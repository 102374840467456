import React from 'react';
import styled from 'styled-components';
import { BiLogOutCircle }from "react-icons/bi";
import { ImArrowUpRight2 } from "react-icons/im";
import { RiPercentFill } from "react-icons/ri";
import { GiSaveArrow } from "react-icons/gi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { AiTwotoneBank } from "react-icons/ai";

const Home = () => {
  return (
    <>
        <Center>
        <CenterHeader>
            <HeaderLeft>
                <CenterLogo>Azul Yazid.</CenterLogo>
                <CenterHeaderSubTitle>Rebonjour</CenterHeaderSubTitle>
            </HeaderLeft>
            <HeaderRight>
                <Logout><BiLogOutCircle />Déconnexion</Logout>
            </HeaderRight>
        </CenterHeader>
        <CenterBody>
            <Card style={{backgroundColor : "#ffeee2"}}>
                <CardLeft>
                    <CardTitle>Pièces</CardTitle>
                    <CardSubTitle>Nbr de pièces au totale</CardSubTitle>
                    <CardNumber>1024</CardNumber>
                </CardLeft>
                <CardRight>
                    <ImArrowUpRight2 color="#d98c59" style={{marginLeft : "15px"}}/>
                </CardRight>
            </Card>
            <Card style={{backgroundColor : "#f4f5f9"}}>
                <CardLeft>
                    <CardTitle>Vente</CardTitle>
                    <CardSubTitle>Nbr de ventes faite</CardSubTitle>
                    <CardNumber>95</CardNumber>
                </CardLeft>
                <CardRight>
                    <ImArrowUpRight2 style={{marginLeft : "15px"}}/>
                </CardRight>
            </Card>
            <Card style={{backgroundColor : "#eefcef"}}>
                <CardLeft>
                    <CardTitle>Fournisseurs</CardTitle>
                    <CardSubTitle>Nbr de fournisseurs</CardSubTitle>
                    <CardNumber>15</CardNumber>
                </CardLeft>
                <CardRight>
                    <ImArrowUpRight2 color="#7dcd83" style={{marginLeft : "15px"}}/>
                </CardRight>
            </Card>
            <Card style={{backgroundColor : "#e6f5fa"}}>
                <CardLeft>
                    <CardTitle>Factures</CardTitle>
                    <CardSubTitle>Nbr de factures</CardSubTitle>
                    <CardNumber>51</CardNumber>
                </CardLeft>
                <CardRight>
                    <ImArrowUpRight2 color="#73c0da" style={{marginLeft : "15px"}}/>
                </CardRight>
            </Card>
        </CenterBody>
        </Center>
        <Right>
            <Wrapper>
                <WrapperTitle>Statistiques</WrapperTitle>
                <Info>
                    <InfoLeft><CircleImage><RiPercentFill color="black"/></CircleImage></InfoLeft>
                    <InfoRight>
                        <InfoNumber>23k</InfoNumber>
                        <InfoTitle>ventes</InfoTitle>
                    </InfoRight>
                </Info>
                <Info>
                    <InfoLeft><CircleImage><GiSaveArrow color="black"/></CircleImage></InfoLeft>
                    <InfoRight>
                        <InfoNumber>1k</InfoNumber>
                        <InfoTitle>Pièces au totale</InfoTitle>
                    </InfoRight>
                </Info>
                <Info>
                    <InfoLeft><CircleImage><FaRegMoneyBillAlt color="black"/></CircleImage></InfoLeft>
                    <InfoRight>
                        <InfoNumber>1,5k</InfoNumber>
                        <InfoTitle>Factures générées</InfoTitle>
                    </InfoRight>
                </Info>
                <Info>
                    <InfoLeft><CircleImage><AiTwotoneBank color="black"/></CircleImage></InfoLeft>
                    <InfoRight>
                        <InfoNumber>20k</InfoNumber>
                        <InfoTitle>Revenues</InfoTitle>
                    </InfoRight>
                </Info>
            </Wrapper>
            <p style={{fontStyle: "italic"}}>* Développé par <a href="mailto:eplicseby@gmail.com">yandevdz</a></p>
        </Right>
    </>
  );
};

export default Home;

const  InfoTitle = styled.span`
	opacity: 0.5;
`;
const  InfoNumber = styled.span`
	font-size: 19px;
	font-weight: bold;
`;
const  InfoRight = styled.div`
	display: flex;
	flex-direction: column;
`;
const  InfoLeft = styled.div``;
const CircleImage = styled.div`
	width : 40px;
	height : 40px;
	background-color: #e2e2e2;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Info = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap : 20px;
	margin-top: 25px;
`;

const WrapperTitle = styled.div`
	font-weight: bold;
	font-size: 22px;
`;


const Wrapper = styled.div`
	background-color: black;
	margin : 15px;
	margin-left : 0px;
	/* width: 100%; */
	border-radius: 25px;
	color : #e2e2e2;
	padding: 25px 30px;
`;

const CardNumber = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-top: 8px;
`;
const CardSubTitle = styled.div`
	font-size: 14px;
	color : grey;
`;
const CardTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
`;
const CardRight = styled.div`
    justify-content: end;
	display : flex;
	flex : 1;
`;
const CardLeft = styled.div``;
const Card = styled.div`
	min-width: 250px;
	border-radius: 25px;
	padding : 25px;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	box-shadow: 0px 0px 1px lightgray;
	transition: all 0.3s;
	&:hover {
		box-shadow: 0px 0px 15px lightgray;
		transform: scale(1.05);
		transition: all 0.3s;
	}
`;

const HeaderLeft = styled.div``;
const HeaderRight = styled.div`
	
`;
const Logout = styled.a`
	cursor: pointer;
	display : flex;
	align-items: center;
	justify-content: center;
	gap : 5px;
`;

const CenterHeaderSubTitle = styled.span`
	color : grey;
	font-style : italic;
`;

const CenterLogo = styled.h2`

`;

const CenterBody = styled.div`
	display: flex;
	margin-top: 40px;
	flex-direction: row;
	gap: 25px;
	flex-wrap: wrap;
`;

const CenterHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-right: 25px;
`;

const Center = styled.div`
	display: flex;
	padding : 40px 25px 25px 25px;
	flex-direction: column;
	justify-content: center;
`;

const Right = styled.div`
	display: flex;
	/* height : fit-content; */
	flex-direction: column;
	justify-content: center;
	padding-top: 25px;
`;
