import "./App.css";
import styled from "styled-components/macro";
import { NavLink, Outlet } from "react-router-dom";
import {
  BiHomeAlt,
  BiBell,
  BiStats,
  BiDollarCircle,
  BiCartAlt,
  BiUserVoice,
  BiCog,
} from "react-icons/bi";
import { BsFileEarmarkPdf } from "react-icons/bs";

function App() {
  return (
    <Container>
      <Left>
        <Logo>Autodis.</Logo>
        <Navs>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/"}
          >
            <Li>
              <BiHomeAlt size={24} />
              accueil
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/ventes"}
          >
            <Li>
              <BiDollarCircle size={24} />
              ventes
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/stock"}
          >
            <Li>
              <BiCartAlt size={24} />
              stock
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/stats"}
          >
            <Li>
              <BiStats size={24} />
              stats
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/factures"}
          >
            <Li>
              <BsFileEarmarkPdf size={24} />
              factures
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/fournisseurs"}
          >
            <Li>
              <BiUserVoice size={24} />
              fournisseurs
            </Li>
          </StyledNavLink>
          <StyledNavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/alerts"}
          >
            <Li>
              <BiBell size={24} />
              alert
            </Li>
          </StyledNavLink>
        </Navs>
        <Profile>
          <img src="http://placeimg.com/80/80/any" alt="altProfile" />
          <span>
            <BiCog size={16} /> Yazid
          </span>
        </Profile>
      </Left>
      <Outlet />
      <Bottom>
        <p>
          👉 Ceci est la nouvelle version du logiciel, si vous voulez revenir a
          l'ancienne veuilez <a href="/">cliquer ici</a>.
        </p>
      </Bottom>
    </Container>
  );
}

export default App;

const Bottom = styled.div`
  background-color: #ffeee2;
  grid-column: 2 / 4;
  grid-row: 2 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-family: "Trebuchet MS";
    color: #d48550;
    font-weight: bold;
  }
  & a {
    color: black;
    font-style: italic;
  }
`;

const Profile = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  & img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    align-self: center;
    margin-bottom: 5px;
    border: 2px dashed grey;
    padding: 2px;
  }
  & span {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    gap: 2px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;
  }
`;

const Navs = styled.ul`
  list-style: none;
  gap: 25px;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  & .links {
    margin-left: 10px;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  gap: 15px;
  display: flex;
  &:hover {
    font-weight: bold;
  }
  &.active {
    border-left: 5px solid black;
    transition: all 0.3s;
    font-weight: bold;
  }
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  border-left: 5px solid white;
  height: 40px;
  padding-left: 25px;
  cursor: pointer;
  transition: all 0.3s;
  gap: 15px;
  text-transform: capitalize;
`;

const Logo = styled.h1`
  padding-left: 25px;
`;

const Left = styled.div`
  background-color: white;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid #ededed;
  grid-row: 1 / 3;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 300px 1fr 300px;
  grid-template-rows: 1fr 50px;
  max-width: 1600px;
  margin: auto;
`;
