import React from "react";
import styled from "styled-components/macro";
import FacturePDF from "../components/FacturePDF";

import { HiDownload } from "react-icons/hi";

const RedactionFactures = () => {
	return (
		<>
			<Center>
				<DownloadIcon title="Télécharger la facture">
					<HiDownload />
				</DownloadIcon>
				<FacturePDF />
			</Center>
			<Right>
				<WrapperLastResearch>
					<Title>Derniers ajouts.</Title>
					<LastResearch>
						<Item>facture N°250</Item>
						<Item>facture N°251</Item>
					</LastResearch>
				</WrapperLastResearch>
			</Right>
		</>
	);
};

export default RedactionFactures;

const DownloadIcon = styled.div`
	position: fixed;
	cursor: pointer;
	font-size: 38px;
	margin-left: -820px;
`;

const Item = styled.span`
	border-radius: 25px;
	padding: 10px 15px;
	:hover {
		background-color: #423f46;
		cursor: pointer;
	}
`;

const LastResearch = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Title = styled.h3`
	padding: 10px 15px;
`;

const WrapperLastResearch = styled.div`
	background-color: black;
	color: #e2e2e2;
	border-radius: 25px;
	padding: 10px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const Center = styled.div`
	background-color: #f6f8fa;
	display: flex;
	flex: 1;
	justify-content: center;
	padding: 25px;
	overflow-y: auto;
	position: relative;
`;

const Right = styled.div`
	/* border: 1px solid blue; */
	padding: 50px 25px;
`;
