import React from "react";
import styled from "styled-components/macro";
import { Page, Text, Document, View } from "@react-pdf/renderer";

import {IoIosAddCircle} from "react-icons/io";

import { NumberToLetter } from "../utils/NumbersToSentences";
import TableProduct from "./TableProduct"

const nf = new Intl.NumberFormat("dz-DZ", { minimumFractionDigits: 2 });

function FacturePDF() {
  // get today date 
  var today = new Date();
  var date = today.getFullYear()+'-'
              +(today.getMonth().length == 1 ? today.getMonth()+1 : "0"+(today.getMonth()+1) )
              +'-'+today.getDate();

  const [facture, setFacture] = React.useState({
    numero: null,
    date,
    rc: null,
    ai: null,
    mf: null,
    mr: null,
    lignes: [],
    total : 0
  });

  const addNewLine = () => {
    return setFacture({ ...facture, lignes : [...facture.lignes, {
        id : (facture.lignes.length > 0 ? facture.lignes[facture.lignes.length - 1].id : 0) + 1,
        designation: "",
        quantite : 0,
        prixUnitaire : 0,
      }] 
    });
  }

  const removeLine = (indexLine) => {
    const newFacture = facture.lignes.filter((ligne) => ligne.id !== indexLine);
    return setFacture({ ...facture, lignes : newFacture });
  }

  const calculateTotal = () => {
    if(facture.lignes.length === 0) return 0; 
    let total = 0;
    facture.lignes.forEach((element) => {
      total = total + (element.quantite * element.prixUnitaire);
    });
    return total;
  }

  React.useEffect(() => {
    let total = calculateTotal();
    setFacture({ ...facture, total });
  }, [facture.lignes]);

  return (
    <DocumentPDF>
      <PagePDF size="A4">
        {/* header Facture */}
        <Row>
          <PageLeft>
            <Texte className="bold">Inoudjal Boualem</Texte>
            <Texte>zone d'activité - Béjaia</Texte>
            <Texte>
              <b>RC </b> <span> &nbsp; N° 06/00-1000545أ</span>
            </Texte>
            <Texte>
              <b>NIS </b> <span> &nbsp; N° 198006010336050</span>
            </Texte>
            <Texte>
              <b>NIF </b> <span> &nbsp; N° 180060103360102</span>
            </Texte>
          </PageLeft>
          <PageRight>
            <PageRightHeader>
              <Texte>Facture</Texte>
              <Texte>فاتورة</Texte>
            </PageRightHeader>
            <PageRightRow>
              <Texte>N° : </Texte>
              <input type="text" />
              <Texte> : رقم</Texte>
            </PageRightRow>
            <PageRightRow>
              <Texte>Le : </Texte>
              <input type="date" value={facture.date} onChange={(evt) => {
                setFacture({ ...facture, date : evt.target.value});
              }} />
              <Texte> : في</Texte>
            </PageRightRow>
          </PageRight>
        </Row>
        {/* sous header */}
        <Row>
          <SubHeader>
            <PageLeftSubHeader>
              <Texte>R.C. N°: </Texte>
              <input type="text" value={facture.rc} onChange={(evt) => setFacture({ ...facture, rc : evt.target.value })}/>
              <Texte>:س.ت رقم</Texte>
            </PageLeftSubHeader>
            <PageLeftSubHeader>
              <Texte>A.I. N°:</Texte>
              <input type="text" value={facture.ai} onChange={(evt) => setFacture({ ...facture, ai : evt.target.value })}/>
              <Texte>:الرقم الجبائي</Texte>
            </PageLeftSubHeader>
            <PageLeftSubHeader>
              <Texte>M.F. N° :</Texte>
              <input type="text" value={facture.mf} onChange={(evt) => setFacture({ ...facture, mf : evt.target.value })} />
              <Texte>:رقم المادة</Texte>
            </PageLeftSubHeader>
          </SubHeader>
          <View style={{ flex: 0.58 }}>
            <PageLeftSubHeader style={{ padding: "5px 20px" }}>
              <Texte>M.F. N° :</Texte>
              <input type="text" value={facture.mr} onChange={(evt) => setFacture({ ...facture, mr : evt.target.value })} />
              <Texte>:السيد</Texte>
            </PageLeftSubHeader>
          </View>
        </Row>
        {/* Table content */}
        <Row bg="#000">
          <Th size="15%">Quantité</Th>
          <Th size="50%">Désignation</Th>
          <Th size="18%">Prix Unitaire</Th>
          <Th size="17%">Montant</Th>
        </Row>
        {
          facture.lignes.map((element, i) => {
            return (
              <TableProduct element={element} removeLine={removeLine} facture={facture} setFacture={setFacture} />
            )
          })
        }
        {/* button add ligne */}
        <Row style={{cursor : "pointer", marginTop : "10px"}} onClick={addNewLine} >
          <IoIosAddCircle color={"#73c0da"} size={24} />
          <span style={{color : "#73c0da"}}>Ajouter une ligne</span>
        </Row>
        {/* footer */}
        <Total>
          <TotalLeft></TotalLeft>
          <TotalRight>
            <TotalRightRow>
              <TotalRightRowLeft>
                <Text>Total</Text>
              </TotalRightRowLeft>
              <TotalRightRowRight>
                <Text>{nf.format(facture.total)}</Text>
              </TotalRightRowRight>
            </TotalRightRow>
            <TotalRightRow style={{ backgroundColor: "#e3e3e3" }}>
              <TotalRightRowLeft>
                <Text>TTC</Text>
              </TotalRightRowLeft>
              <TotalRightRowRight>
                <Text>{nf.format(facture.total)}</Text>
              </TotalRightRowRight>
            </TotalRightRow>
          </TotalRight>
        </Total>
        <Footer>
          <Text>
            Arrêtée la présente facture à la somme de : <b>{NumberToLetter(facture.total)} Dinars</b>
          </Text>
        </Footer>
      </PagePDF>
    </DocumentPDF>
  );
}

export default FacturePDF;

const CloseIcon = styled.div`
    color: red;
    position: absolute;
    right: -30px;
    cursor: pointer;
    top: 15px;
    font-size: 22px;
    width: 10%;
    text-align: right;
    display: none;
    :hover {
      display: block;
    }
`;

const Footer = styled(View)`
  border-top: 1px solid black;
  padding: 8px 0px;
  margin-top: 50px;
`;

const TotalRightRowRight = styled(View)`
  display: flex;
  padding: 10px;
  Text {
    font-size: 18px;
  }
`;
const TotalRightRowLeft = styled(View)`
  padding: 10px;
  display: flex;
  Text {
    font-weight: bold;
    font-size: 18px;
  }
`;
const TotalRightRow = styled(View)`
  display: flex;
  justify-content: space-between;
`;
const TotalRight = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const TotalLeft = styled(View)`
  display: flex;
  flex: 1;
`;
const Total = styled(View)`
  display: flex;
  flex: 1;
  margin-top: 18px;
`;

const SubHeader = styled(View)`
  flex: 0.42;
  flex-direction: column;
  /* border: 2px solid black; */
  justify-content: center;
  border-radius: 8px;
`;

const Th = styled(Text)`
  width: ${(props) => props.size || 0};
  display: flex;
  padding: 5px 0px;
  textarea {
    width: 95%;
    resize: vertical;
    border: 0;
    outline: 0;
    font-size: 18px;
    &:hover,
    :focus {
      border: 1px dashed #d98c59;
      background-color: #ffeee2;
    }
  }
  input {
    display: flex;
    width: 100%;
    font-size: 17px;
    border: 0px;
    outline: 0;
    &:hover:not([disabled]),
    :focus:not([disabled]) {
      background-color: #ffeee2;
      border: 1px dashed #d98c59;
    }
  }
`;

const Texte = styled(Text)`
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  &.bold {
    font-weight: bold;
    font-size: 17px;
  }
`;

const PageRightHeader = styled(View)`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding-bottom: 8px;
  border-bottom: 2px solid black;
  Text {
    font-size: 25px;
    font-weight: bold;
  }
`;

const PageRightRow = styled(View)`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding-bottom: 8px;
  padding-top: 8px;
  Text {
    display: flex;
    flex: 0.2;
    font-size: 17px;
    margin-bottom: 0;
  }
  input {
    display: flex;
    flex: 0.6;
    width: 100%;
    font-size: 17px;
    border: 0px;
    outline: 0;
    &:hover,
    :focus {
      background-color: #ffeee2;
      border: 1px dashed #d98c59;
    }
  }
`;

const PageLeftSubHeader = styled(View)`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 5px 0px;
  Text {
    display: flex;
    font-size: 15px;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  Text:last-child {
    justify-content: flex-end;
  }
  input {
    display: flex;
    flex: 1;
    width: 100%;
    font-size: 18px;
    border: 0px;
    outline: 0;
    padding: 0px 5px;
    &:hover,
    :focus {
      background-color: #ffeee2;
      border: 1px dashed #d98c59;
    }
  }
`;

const PageRight = styled(View)`
  flex: 0.6;
`;

const PageLeft = styled(View)`
  flex: 0.4;
  flex-direction: column;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  margin-right: 15px;
`;

const Row = styled(View)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 5px;
  background-color: ${(props) => props.bg || "transparent"};
  color: ${(props) => (props.bg ? "white" : "black")};
  font-weight: ${(props) => (props.bg ? "bold" : "none")};
  padding: ${(props) => (props.bg ? "5px 10px" : "0")};
  margin-top: ${(props) => (props.bg ? "10px" : "0")};
  padding-bottom: ${(props) => (props.bg ? "0px" : "15px")};
  border-bottom: ${(props) => props.bb || "0"};
  :hover {
    ${CloseIcon} {
      display : block;
    }
  }
`;

const PagePDF = styled(Page)`
  display: flex;
  flex: 1;
  height: fit-content;
  flex-direction: column;
`;

const DocumentPDF = styled(Document)`
  display: flex;
  width: 700px;
  height: fit-content;
  background: #fff;
  padding: 40px 35px;
  box-shadow: 0 0 17px 0 rgb(16 40 73 / 9%);
`;
