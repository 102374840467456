import React from "react";
import styled from "styled-components";

const nf = new Intl.NumberFormat("dz-DZ", {
  style: "currency",
  currency: "DZD",
});

const Vente = () => {
  return (
    <>
      <Center>
        <CenterHeader>
          <h3>Filtres.</h3>
          <FormGroup>
            <Label style={{ flex: "0.5" }}>du</Label>
            <FormGroupDivide>
              <WrapperInput>
                <CenterBarCodeInput
                  id="achat"
                  type="date"
                  placeholder="Ajouter le prix d'achat ..."
                />
              </WrapperInput>
            </FormGroupDivide>
            <Label style={{ flex: "0.5", justifyContent: "center" }}>au</Label>
            <FormGroupDivide>
              <WrapperInput>
                <CenterBarCodeInput
                  id="vente"
                  type="date"
                  placeholder="Ajouter le prix de vente ..."
                />
              </WrapperInput>
            </FormGroupDivide>
          </FormGroup>
        </CenterHeader>
        <div
          style={{
            backgroundColor: "white",
            height: "calc(100vh - 185px)",
            overflowY: "scroll",
          }}
        >
          <Table>
            <thead>
              <tr>
                <th>Désignation</th>
                <th>Référence</th>
                <th>P.Achat</th>
                <th>P.Vente</th>
                <th>Qts vendu</th>
                <th>Total</th>
                <th>Bénéfice</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="designation">
                  rétroviseur DROIT GRAND i10 IBORG
                </td>
                <td>87632131ref</td>
                <td>
                  <span>{nf.format(35000)}</span>
                </td>
                <td>{nf.format(45200)}</td>
                <td>2</td>
                <td>{nf.format(45200)}</td>
                <td className="benefice">{nf.format(45200)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Center>
      <Right>
        <WrapperLastResearch>
          <Title>Bénéfice aujourd'hui.</Title>
          <LastResearch>
            <Item>{nf.format(155230)}</Item>
          </LastResearch>
        </WrapperLastResearch>
      </Right>
    </>
  );
};

export default Vente;

const Label = styled.label`
  /* margin-right: 15px; */
  text-transform: capitalize;
  display: flex;
  flex: 0.1;
`;
const CenterBarCodeInput = styled.input`
  height: 42px;
  border-radius: 25px;
  border: 1px solid lightgray;
  padding: 15px;
  outline: 0;
  display: flex;
  flex: 1;
`;

const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const FormGroupDivide = styled.div`
  display: flex;
  flex: 1;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.span`
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 25px;
  font-weight: bold;
`;

const LastResearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  padding: 10px 15px;
  color: black;
`;

const WrapperLastResearch = styled.div`
  background-color: #eefcef;
  color: #7dcd83;
  border-radius: 25px;
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Table = styled.table`
  border-spacing: 0;
  padding-left: 25px;
  tr {
    border-bottom: 1px solid black;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    th {
      font-weight: 100;
    }
  }

  th,
  td {
    margin: 0;
    padding: 10px 25px 10px 0px;
    border-bottom: 2px solid #ededed;
    text-align: left;
    :last-child {
      border-right: 0;
    }
  }

  button {
    border-radius: 25px;
    width: 25px;
    height: 25px;
    border: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s;
    justify-content: center;
    align-items: center;
    display: flex;
    &:hover {
      background-color: #ffeee2;
      color: #d98c59;
      opacity: 1;
      transition: all 0.2s;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .blured > span {
    filter: blur(3px);
    transition: all 0.3s;
    :hover {
      filter: blur(0px);
      transition: all 0.3s;
    }
  }

  .designation {
    font-weight: bold;
  }

  .benefice {
    color: rgb(125, 205, 131);
  }
`;

const CenterHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 25px;
  background-color: white;
  gap: 15px;
`;

const Center = styled.div`
  /* border: 1px solid red; */
  /* background-color: #f6f8fa; */
`;

const Right = styled.div`
  /* border: 1px solid blue; */
  padding: 50px 25px;
`;
