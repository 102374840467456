import React from "react";
import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import { AiOutlineEnter } from "react-icons/ai";
import { Link } from "react-router-dom";

const nf = new Intl.NumberFormat("dz-DZ", {
  style: "currency",
  currency: "DZD",
});

const Factures = () => {
  return (
    <>
      <Center>
        <CenterHeader>
          <h3>Rechercher.</h3>
          <WrapperSearch>
            <FiSearchStyle size={20} />
            <CenterSearchInput
              type="text"
              placeholder="Trouver une facture ..."
            />
            <RightEnterBtn>
              <AiOutlineEnter size={15} />
              Enter
            </RightEnterBtn>
          </WrapperSearch>
        </CenterHeader>
        <div
          style={{
            backgroundColor: "white",
            height: "calc(100vh - 185px)",
            overflowY: "scroll",
          }}
        >
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Au nom de</th>
                <th>Nbr de produits</th>
                <th>Montant</th>
                <th>Date</th>
                <th>Lien facture</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="designation">1</td>
                <td>Benslimane yanis</td>
                <td>5</td>
                <td>{nf.format(17500)}</td>
                <td>2022-02-17</td>
                <td>
                  <a href="#">facture_yanis.pdf</a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Center>
      <Right>
        <WrapperLastResearch>
          <Title>Ajouter une facture.</Title>
          <LastResearch>
            <Link to={"/redactionFacture"} style={{color : "white", padding: "10px 15px"}}>Ajout</Link>
          </LastResearch>
        </WrapperLastResearch>
      </Right>
    </>
  );
};

export default Factures;

const Item = styled.span`
  border-radius: 25px;
  padding: 10px 15px;
  :hover {
    background-color: #423f46;
    cursor: pointer;
  }
`;

const LastResearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  padding: 10px 15px;
`;

const WrapperLastResearch = styled.div`
  background-color: black;
  color: #e2e2e2;
  border-radius: 25px;
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
// right section ---- UP

const RightEnterBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  color: grey;
  gap: 5px;
  padding: 0px 15px;
`;

const Table = styled.table`
  border-spacing: 0;
  padding-left: 25px;
  width: 100%;
  tr {
    border-bottom: 1px solid black;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    th {
      font-weight: 100;
    }
  }

  th,
  td {
    margin: 0;
    padding: 10px 25px 10px 0px;
    border-bottom: 2px solid #ededed;
    text-align: left;
    :last-child {
      border-right: 0;
    }
  }

  button {
    border-radius: 25px;
    width: 25px;
    height: 25px;
    border: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s;
    justify-content: center;
    align-items: center;
    display: flex;
    &:hover {
      background-color: #ffeee2;
      color: #d98c59;
      opacity: 1;
      transition: all 0.2s;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .blured > span {
    filter: blur(3px);
    transition: all 0.3s;
    :hover {
      filter: blur(0px);
      transition: all 0.3s;
    }
  }

  .designation {
    font-weight: bold;
  }
`;

const WrapperSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FiSearchStyle = styled(FiSearch)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const CenterSearchInput = styled.input`
  height: 42px;
  width: 100%;
  border-radius: 0px 25px 25px 0px;
  border: 1px solid lightgray;
  border-left: 0;
  padding: 0px 5px;
  outline: 0;
`;

const CenterHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 25px;
  background-color: white;
  gap: 15px;
`;

const Center = styled.div`
  /* border: 1px solid red; */
  /* background-color: #f6f8fa; */
`;

const Right = styled.div`
  /* border: 1px solid blue; */
  padding: 50px 25px;
`;
