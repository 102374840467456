import React from "react";
import styled from "styled-components";

import { AiOutlineBarcode, AiOutlineUserSwitch } from "react-icons/ai";
import { FiType } from "react-icons/fi";
import { VscReferences } from "react-icons/vsc";
import { BiDollarCircle, BiLibrary } from "react-icons/bi";
import { GrMultiple } from "react-icons/gr";
import { MdOutlineRateReview } from "react-icons/md";

const Stock = () => {
  return (
    <>
      <Center>
        <CenterHeader>
          <h3>Ajouter une pièce.</h3>
          <FormGroup>
            <Label htmlFor="codebarre">code barre</Label>
            <WrapperInput>
              <IconBarCodeStyle size={20} />
              <CenterBarCodeInput
                id="codebarre"
                type="text"
                placeholder="Ajouter le code barre ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="reference">La référence</Label>
            <WrapperInput>
              <IconReferences size={20} />
              <CenterBarCodeInput
                id="reference"
                type="text"
                placeholder="Ajouter la référence de la pièce ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="marque">Type de la pièce</Label>
            <WrapperInput>
              <IconType size={20} />
              <CenterBarCodeInput
                id="marque"
                type="text"
                placeholder="Marque de la pièce ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="fournisseur">fournisseurs</Label>
            <WrapperInput>
              <IconUserSwitch size={20} />
              <Select name="fournisseur" id="fournisseur">
                <option value="0">Choix</option>
                <option value="1">yanis</option>
                <option value="2">idir</option>
                <option value="3">meriem</option>
              </Select>
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label style={{ flex: "0.5" }}>prix achat</Label>
            <FormGroupDivide>
              <WrapperInput>
                <IconBiDollarCircle size={20} />
                <CenterBarCodeInput
                  id="achat"
                  type="text"
                  placeholder="Ajouter le prix d'achat ..."
                />
              </WrapperInput>
            </FormGroupDivide>
            <Label style={{ flex: "0.5", justifyContent: "center" }}>
              prix vente
            </Label>
            <FormGroupDivide>
              <WrapperInput>
                <IconBiDollarCircle size={20} />
                <CenterBarCodeInput
                  id="vente"
                  type="text"
                  placeholder="Ajouter le prix de vente ..."
                />
              </WrapperInput>
            </FormGroupDivide>
          </FormGroup>
          <FormGroup>
            <Label>quantité</Label>
            <WrapperInput>
              <IconGrMultiple size={20} />
              <CenterBarCodeInput
                id="quantite"
                type="text"
                placeholder="Quantité ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label>emplacement</Label>
            <WrapperInput>
              <IconBiLibrary size={20} />
              <CenterBarCodeInput
                id="emplacement"
                type="text"
                placeholder="Emplacement ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label>remarque</Label>
            <WrapperInput>
              <IconMdOutlineRateReview size={20} />
              <CenterBarCodeInput
                id="remarque"
                type="text"
                placeholder="Remarque ..."
              />
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label>Alerter ?</Label>
            <WrapperInput style={{ gap: "15px" }}>
              <div>
                <input
                  style={{ marginRight: "8px" }}
                  type="radio"
                  name="radio"
                  id="oui"
                />
                <label htmlFor="oui">Oui</label>
              </div>
              <div>
                <input
                  style={{ marginRight: "8px" }}
                  type="radio"
                  name="radio"
                  id="non"
                />
                <label htmlFor="non">Non</label>
              </div>
            </WrapperInput>
          </FormGroup>
          <FormGroup>
            <Label></Label>
            <WrapperInput>
              <Button>Ajouter la nouvelle piece</Button>
            </WrapperInput>
          </FormGroup>
        </CenterHeader>
      </Center>
      <Right>
        <WrapperLastResearch>
          <Title>Ajoutée récemment.</Title>
          <LastResearch>
            <Item>capot dacia 2</Item>
            <Item>capot dacia 2</Item>
            <Item>capot dacia 2</Item>
          </LastResearch>
        </WrapperLastResearch>
      </Right>
    </>
  );
};

export default Stock;

const Button = styled.button`
  height: 42px;
  border-radius: 25px;
  border: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: black;
  color: white;
  font-weight: 600;
  opacity: 0.7;
  transition: all 0.3s;
  :hover {
    opacity: 1;
    transition: all 0.3s;
  }
`;

const Item = styled.span`
  border-radius: 25px;
  padding: 10px 15px;
  :hover {
    background-color: #423f46;
    cursor: pointer;
  }
`;

const LastResearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  padding: 10px 15px;
`;

const WrapperLastResearch = styled.div`
  background-color: black;
  color: #e2e2e2;
  border-radius: 25px;
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const IconMdOutlineRateReview = styled(MdOutlineRateReview)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconBiLibrary = styled(BiLibrary)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconBarCodeStyle = styled(AiOutlineBarcode)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconGrMultiple = styled(GrMultiple)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconBiDollarCircle = styled(BiDollarCircle)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconUserSwitch = styled(AiOutlineUserSwitch)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const Label = styled.label`
  /* margin-right: 15px; */
  text-transform: capitalize;
  display: flex;
  flex: 0.2;
`;

const IconReferences = styled(VscReferences)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const IconType = styled(FiType)`
  align-self: center;
  border: 1px solid red;
  height: 42px;
  width: 42px;
  padding: 10px;
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  border-color: lightgrey;
`;

const CenterBarCodeInput = styled.input`
  height: 42px;
  border-radius: 0px 25px 25px 0px;
  border: 1px solid lightgray;
  border-left: 0;
  padding: 0px 5px;
  outline: 0;
  display: flex;
  flex: 1;
`;

const Select = styled.select`
  height: 42px;
  border-radius: 0px 25px 25px 0px;
  border: 1px solid lightgray;
  border-left: 0;
  padding: 0px 5px;
  outline: 0;
  display: flex;
  flex: 1;
`;

const FormGroupDivide = styled.div`
  display: flex;
  flex: 1;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CenterHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 25px;
  background-color: white;
  gap: 15px;
`;

const Center = styled.div`
  /* border: 1px solid red; */
  /* background-color: #f6f8fa; */
`;

const Right = styled.div`
  /* border: 1px solid blue; */
  padding: 50px 25px;
`;
