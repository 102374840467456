import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
// routes
import Alerts from "./routes/alerts";
import Vente from "./routes/Vente";
import Home from "./routes/Home";
import Stock from "./routes/Stock";
import Stats from "./routes/Stats";
import Factures from "./routes/Factures";
import RedactionFacture from "./routes/RedactionFacture";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="ventes" element={<Vente />} />
          <Route path="stock" element={<Stock />} />
          <Route path="stats" element={<Stats />} />
          <Route path="factures" element={<Factures />} />
          <Route path="redactionFacture" element={<RedactionFacture />} />
          <Route path="alerts" element={<Alerts />} />
        </Route>
        <Route path="*" element={<p>en developement ...</p>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
