import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Text, View } from "@react-pdf/renderer";
import {IoIosCloseCircle} from "react-icons/io";

const nf = new Intl.NumberFormat("dz-DZ", { minimumFractionDigits: 2 });

const TableProduct = ({element, removeLine, facture, setFacture}) => {
    return (
        <Row bb="1px solid lightgray" key={element.id}>
            <Th size="15%">
                <input type="number" value={element.quantite} onChange={(evt) => {   
                    let items = [...facture.lignes];
                    let item = {...items[element.id-1]};
                    item.quantite = parseFloat(evt.target.value);
                    items[element.id-1] = item;
                    setFacture({ ...facture, lignes : items});
                }}/>
            </Th>
            <Th size="50%">
                <textarea
                name="designation"
                placeholder="Entrez la désignation du produit"
                onChange={(evt) => {
                    let items = [...facture.lignes];
                    let item = {...items[element.id-1]};
                    item.designation = evt.target.value;
                    items[element.id-1] = item;
                    setFacture({ ...facture, lignes : items});
                }}
                >
                    {element.designation}
                </textarea>
            </Th>
            <Th size="18%">
                <input type="text" value={element.prixUnitaire} onChange={(evt) => {
                    let items = [...facture.lignes];
                    let item = {...items[element.id-1]};
                    item.prixUnitaire = parseFloat(evt.target.value);
                    items[element.id-1] = item;
                    setFacture({ ...facture, lignes : items});
                }} />
            </Th>
            <Th size="17%">
                <input type="text" value={parseFloat(element.quantite) * parseFloat(element.prixUnitaire)} disabled />
            </Th>
            <CloseIcon onClick={() => {removeLine(element.id)}}>
                <IoIosCloseCircle color="red"/>
            </CloseIcon>
        </Row>
    )
}

export default TableProduct;

const CloseIcon = styled.div`
    color: red;
    position: absolute;
    right: -30px;
    cursor: pointer;
    top: 15px;
    font-size: 22px;
    width: 10%;
    text-align: right;
    display: none;
    :hover {
      display: block;
    }
`;

const Th = styled(Text)`
  width: ${(props) => props.size || 0};
  display: flex;
  padding: 5px 0px;
  textarea {
    width: 95%;
    resize: vertical;
    border: 0;
    outline: 0;
    font-size: 18px;
    &:hover,
    :focus {
      border: 1px dashed #d98c59;
      background-color: #ffeee2;
    }
  }
  input {
    display: flex;
    width: 100%;
    font-size: 17px;
    border: 0px;
    outline: 0;
    &:hover:not([disabled]),
    :focus:not([disabled]) {
      background-color: #ffeee2;
      border: 1px dashed #d98c59;
    }
  }
`;

const Row = styled(View)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 5px;
  background-color: ${(props) => props.bg || "transparent"};
  color: ${(props) => (props.bg ? "white" : "black")};
  font-weight: ${(props) => (props.bg ? "bold" : "none")};
  padding: ${(props) => (props.bg ? "5px 10px" : "0")};
  margin-top: ${(props) => (props.bg ? "10px" : "0")};
  padding-bottom: ${(props) => (props.bg ? "0px" : "15px")};
  border-bottom: ${(props) => props.bb || "0"};
  :hover {
    ${CloseIcon} {
      display : block;
    }
  }
`;